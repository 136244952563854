class Menu {
    menu: any;
    constructor() {
        $('.c-nav-toggle').on('click', this.toggle.bind(this));
        $('main').on('click', this.destroy.bind(this));
        this.swiperPosition();
        // this.height();
        $(window).resize(this.destroy.bind(this));
        // $(window).resize(this.height.bind(this));
        $(window).scroll(this.scroll.bind(this));
        $(window).resize(this.swiperPosition.bind(this));
          $(".has-subNav").each((index, item) => {
            let items = $(item);
            let subnav = new Subnav(items);
        });
    }
    toggle(event){
        event.preventDefault();
        $('body').toggleClass('is-activeNav');

    }
    destroy(event) {
        if ($('.is-activeNav').length) {
            $('body').removeClass('is-activeNav');
        }
    }

    scroll() {
       if($(window).scrollTop() >= 1)    {
          $('.c-nav-controls').addClass('c-nav-controls--bg')
       }else {
         $('.c-nav-controls').removeClass('c-nav-controls--bg')
       }
    }

    swiperPosition() {
      let marginRight = ($(window).outerWidth() - $(".js-width").innerWidth()) / 2;
      $('.swiper').css({"margin-right": `-${marginRight}px`})

      if ($(window).width() >= 821) {
        $('.swiper').css({"margin-right": `-${marginRight}px`})

      } else if ($(window).width() <= 820) {
        $('.swiper').css({"margin-right": `0`})
      };
    }


}


export class Subnav {
    element: any;
    items: any;
    text: any;
    btn: any;
    constructor(items) {
        this.element = items;
        this.element.find(".c-nav__link--main").on('click', this.toggle.bind(this));
        $(".c-subNav--close").on('click', this.close.bind(this));
        $("body").find(".is-subparent").each((index, item) => {
          let items = $(item);
          let subsubnav = new Subsubnav(items);
      });
    }
    toggle(e) {
      e.preventDefault();
      var parentText = this.element[0].innerText;
      $(".is-subnavnew").addClass("is-open-subnav");
      $($(".is-subnavnew").find(".is-subsubNav")[0]).addClass("is-open-subsubnav");
      $($(".is-subnavnew").find(".is-subparent")[0]).addClass("is-open--sub");
    }
    close() {
      $(".is-open-subnav").removeClass("is-open-subnav");
      $(".is-open-subsubnav").removeClass("is-open-subsubnav");
    }
}

export class Subsubnav {
  element: any;
  items: any;
  text: any;
  btn: any;
  constructor(items) {
      this.element = items;
      this.element.on('click', this.toggle.bind(this));
  }
  toggle() {
    var parentText = this.element[0].innerText;
    $(".is-open--sub").removeClass("is-open--sub");
    this.element.addClass("is-open--sub");
    $(".is-open-subsubnav").removeClass("is-open-subsubnav");
    $('[data-parent="' + parentText + '"]').addClass("is-open-subsubnav");
  }
}

let menu = new Menu();
