class Contact {
    elements = $('.js-contactbtn');
  constructor() {
    let startContact;
    $(".o-overlay").hide();
    this.elements.each((index, item) => {
        let items = $(item);
        let startContact = new ContactForm(items);
    });

  }
}


class ContactForm {
    element: any;

    constructor(items) {
        this.element = items;
        this.element.on("click", this.open.bind(this));
        $(".o-overlay").find('.js-closebtn').on("click", this.close.bind(this));
        // if(!this.lightbox.length) return;

    }
    open(event) {
        $("body").addClass("open-overlay");
        this.element.attr('data-send');
        event.preventDefault();
        $(".o-overlay").show();
        $('input[value="sendto"]').val(this.element.attr('data-send'));
    }

    close() {
        $('.open-overlay').removeClass("open-overlay");
        $('input[value="'+this.element.attr("data-send")+'"]').val("sendto");
        $(".o-overlay").hide();

    }

}

let contact = new Contact();
